<template>
    <fragment>
        <span class="star" v-for="index in avg_rating" :key="index" ></span> 
        <template v-if="avg_rating < 5">      
            <span class="star empty" v-for="n in (5 - avg_rating)" :key="n+'_r'"></span>     
        </template>    
    </fragment>
</template>

<script>
export default {
    props: {
        avgRating: Number
    },
    data() {
        return {
            avg_rating: 0
        }
    },
    created: function(){
        this.avg_rating = this.$props.avgRating
    }
}
</script>